<template>
  <div class="navbar" :class="{'-open': isOpen}">
    <div class="navbar__row">
      <section class="navbar__section">
        <RouterLink :to="{name: 'home'}" class="navbar__logo">
          <img src="/svg/logo.svg" alt="voyage A+"/>
        </RouterLink>
        <div class="navbar__links" v-if="mq.mdPlus">
          <RouterLink :to="{name:'about'}" class="navbar__link"> À propos </RouterLink>
          <RouterLink :to="{name:'voyages'}" class="navbar__link" :class="{'-active' : $route.name === 'voyage'}"> Voyages étudiants </RouterLink>
          <RouterLink :to="{name:'conseils'}" class="navbar__link"> Conseils voyages </RouterLink>
        </div>
      </section>

      <section class="navbar__section">
        <button class="button -light" @click="goToCms" v-if="mq.lgPlus">
          <div class="button__icon-nav">
            <Icon id="user" />
          </div>
          Parent <span>|</span> Organisateurs
        </button>

        <RouterLink :to="{name: 'soumission'}" class="button" v-if="mq.mdPlus">
          <div class="button__icon-nav">
            <img src="/svg/soumission.svg" alt="soumission"/>
          </div>
          Soumission en ligne
        </RouterLink>

        <button class="navbar__burger" @click="isOpen = !isOpen" aria-label="menu">
          <transition name="fade" duration="250" mode="out-in">
            <Icon :id="isOpen ? 'burger-close' : 'burger'" :key="`open-${isOpen}`"/>
          </transition>
        </button>
      </section>
    </div>

    <div class="navbar__content" v-if="isOpen">
      <div class="navbar__content-section">
        <RouterLink :to="{name:'about'}" class="navbar__content-link"> À propos </RouterLink>

        <RouterLink :to="{name:'voyages'}" class="navbar__content-link"> Voyages étudiants </RouterLink>

        <RouterLink :to="{name:'conseils'}" class="navbar__content-link"> Conseils voyages </RouterLink>

        <button class="button -light -full" @click="goToCms">
          <div class="button__icon-nav">
            <Icon id="user" />
          </div>
          Parent <span>|</span> Organisateurs
        </button>

        <RouterLink :to="{name: 'soumission'}" class="button -full" >
          <div class="button__icon-nav">
            <img src="/svg/soumission.svg"/>
          </div>
          Soumission en ligne
        </RouterLink>
      </div>

      <div class="navbar__content-section -info">
        <b class="footer__links-list-title">Coordonnées</b>

        <a :href="footer.gmapLink" target="_blank" class="footer__links-list-item">{{ footer.address }}</a>

        <div class="footer__links-list-item">
          <p><a :href="`tel:${footer.phoneTollfree}`">Sans-frais: {{ footer.phoneTollfree }}</a></p>
          <p><a :href="`tel:${footer.phone}`">Tél: {{ footer.phone }}</a></p>
        </div>

        <a :href="`mailto:${footer.email}}`" class="footer__links-list-item -email">{{ footer.email }}  <Icon id="arrow"/></a>

        <div class="footer__socials">
          <a :href="footer.facebook" target="_blank"><Icon id="facebook"/></a>
          <a :href="footer.instagram" target="_blank"><Icon id="instagram"/></a>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "SiteNav",
  inject: ['mq'],
  watch: {
    '$route.name'() {
      this.isOpen = false
    }
  },
  data() {
    return {
      isOpen : false
    }
  },
  computed: {
    footer() {
      return this.$beet.options.footer || {}
    }
  },
  methods: {
    goToCms() {
      window.open(process.env.VUE_APP_API_URL.replace('/api', ''), '_self')
    }
  }
}
</script>
