<template>
  <SiteNav/>
  <router-view v-slot="{ Component }" >
    <transition name="fade-fast" mode="out-in" appear>
      <div :key="$route.name" class="app" >
        <component :is="Component" v-if="$beet.isReady"/>
        <Loader v-else/>
      </div>
    </transition>
  </router-view>

  <SiteFooter/>

  <Gdpr :options="{backgroundColor: '#E5F3FE', color: '#0D294B'}"/>
</template>

<script>
import SiteNav from "@/components/SiteNav";
//import {useLangRouter} from "@/extensions/langRouter";
import SiteFooter from "@/components/SiteFooter.vue";
import Loader from "@/components/Loader.vue";
import Gdpr from "@/components/gdpr/Gdpr.vue";

export default {
  name: 'App',
  setup() {
    //useLangRouter()
  },
  components: {Gdpr, Loader, SiteFooter, SiteNav },
  watch: {
    '$i18n.locale': {
      handler: function(to) {
        this.$beet.fetchCoreData(to)
      },
      immediate: true
    },
    $route: {
      handler(to, from = {}) {
        const delay = to.name !== from.name ? 400 : 100
        setTimeout(() => {
          const el = to.hash ? document.querySelector(to.hash) : null
          el ?
            el.scrollIntoView({behavior: 'smooth', block: 'start'}) :
            window.scrollTo({top: 0, behavior: 'smooth'})
        }, delay)
      },
      immediate: true
    }
  }
}
</script>
