import {createRouter, createWebHistory} from 'vue-router'
import { generateRoutes } from '@/extensions/langRouter'

const NotFound = () => import('@/views/NotFound')

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home')
    },
    {
        path: '/a-propos',
        name: 'about',
        component: () => import('@/views/About')
    },
    {
        path: '/conseils-aux-voyageurs',
        name: 'conseils',
        component: () => import('@/views/Conseils')
    },
    {
        path: '/soumission-en-ligne',
        name: 'soumission',
        component: () => import('@/views/Soumission')
    },
    {
        path: '/voyages-etudiants',
        name: 'voyages',
        component: () => import('@/views/Voyages')
    },
    {
        path: '/voyages/:slug',
        name: 'voyage',
        component: () => import('@/views/Voyage')
    },
    {
        path: '/conditions-generales',
        name: 'conditions',
        component: () => import('@/views/Conditions')
    },
    {
        path: '/politique-utilisation',
        name: 'politique',
        component: () => import('@/views/Politique')
    },
    {
        path: '/404',
        name: 'not-found',
        component: NotFound
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'catch-all',
        component: NotFound
    }
]

const localizedRoutes = generateRoutes(routes, 'fr')

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: '-active',
    linkExactActiveClass: '-exact-active',
    routes: localizedRoutes
})

export default router
